.stashbox {
  /* height: 120px;
  width: 160px; */
  height: 192px;
  width: 226.5px;
  background: white;
  border-radius: 5px;
  position: relative;
  border-color: #E8EAED;
  border-width: 1px;
  margin-right: 16px;
  margin-bottom: 5px;
}

.stashround {
  /* position: absolute; */
  /* top: 15px;
  left: 15px; */
  height: 25px;
  width: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recentactivity {
  height: 74px;
  width: 100%;
  background-color: white;
  border-radius: 7px;
  position: relative;
  border-color: #E8EAED;
  border-width: 1px;
}

.recentround {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recentround1 {
  left: 15px;
  position: absolute;
  top: 25%;
  height: 25px;
  width: 25px;
  /* border-radius: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-column {
  flex-direction: column; /* Stacks children vertically */
  margin-left: 60px;
}

.box_font{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color:var(--Gray-600, #566062);
}

.box_font1{
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color:var(--Gray-600, #020C0E);
}

.box_font2{
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color:var(--Gray-600, #727A7C);
}