* {
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  
  .container {
    display: flex;
    position: relative; /* Important for positioning child elements correctly */
  }
  
  main {
    width: 100%;
    padding: 20px;
    position: relative; /* Ensure it's underneath the sidebar */
    z-index: 0; /* Main content is below the sidebar */
  }
  
  .sidebar {
    background: #0684ae;
    color: #fff;
    height: 100vh;
    width: 300px;
    position: absolute; /* Sidebar will overlap the content */
    top: 0;
    left: 0;
    z-index: 1; /* Make sure the sidebar is on top of the main content */
    transition: width 0.3s ease; /* Smooth transition when opening/closing */
  }
  
  .sidebar.collapsed {
    width: 50px; /* Width of sidebar when collapsed */
  }
  
  .top_section {
    display: flex;
    align-items: center;
    padding: 20px 15px;
  }
  
  .logo {
    font-size: 30px;
    display: block; /* Only show logo when expanded */
    transition: display 0.3s;
  }
  
  .bars {
    display: flex;
    font-size: 25px;
    margin-left: 50px;
  }
  
  .link {
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.3s;
  }
  
  .link:hover {
    background: lightskyblue;
    color: #000;
    transition: all 0.3s;
  }
  
  .active {
    background: lightskyblue;
    color: #000;
  }
  
  .icon, .link_text {
    font-size: 20px;
  }
  
  .link_text {
    display: inline-block;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  .sidebar.collapsed .link_text {
    opacity: 0; /* Hide the link text when sidebar is collapsed */
    transition: opacity 0.3s ease;
  }
  
  .css-smyodh {
    gap: 0 !important;
  }
  
  .heading{
    color:#020C0E;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 46px;
  }
  .subheading {
    color: #394547;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .formheading {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .formtext {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  